@import '../../assets/styles/common.scss';

/* Component Specific Styles */

.app-footer {
  display: none;
  position: fixed;
  text-transform: uppercase;
  bottom: 0px;
  right: 15px;
  font-size: 10px;

  p {
    margin: 0;
    padding-bottom: 5px;
  }
}
